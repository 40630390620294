@use 'screens' as v;

.appDownloadBadge {
  overflow: hidden;
  border: 1px solid var(--color-common-lightGray);
  border-radius: 8px;
  position: relative;
}

.appDownloadBadgeContainer {
  > img {
    width: 100%;
    height: auto;
  }
}

.large {
  width: 215px;
  height: 60px;
  aspect-ratio: 215 / 60;
}

.small {
  width: 150px;
  height: 40px;
  aspect-ratio: 150 / 40;
}

.smallMobile {
  @media (max-width: v.$screens-laptop) {
    width: 150px;
    height: 40px;
    aspect-ratio: 150 / 40;
  }
}

.auto {
  flex: 1;
  aspect-ratio: 150 / 40;
}
