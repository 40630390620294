@use 'screens' as v;

.link {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listingTitle {
  color: var(--color-text-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 21px;

  @media (max-width: v.$screens-phone) {
    font-size: 16px;
    line-height: 21.6px;
  }
}

.imageContainer {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  aspect-ratio: 301 / 210;
  max-width: 100%;

  img {
    object-fit: cover;
  }
}

.price {
  position: absolute;
  bottom: 16px;
  left: 16px;

  background: var(--color-common-pitchBlack);
  opacity: 0.8;
  border-radius: 8.5px;
  padding: 12px;

  color: var(--color-common-white);
  font-family: var(--font-swimply);
  font-size: 20.39px;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-align: left;

  display: flex;
  align-items: center;
}

.price span {
  font-size: 13.59px;
}

.isQuality {
  position: absolute;
  top: 16px;
  left: 16px;

  display: flex;
  align-items: center;
  gap: 5px;

  background: var(--color-brand-primaryBlue);
  padding: 6px 8px;
  border-radius: 6px;

  color: var(--color-common-white);
  font-size: 14.13px;
  font-weight: 700;
  line-height: 22.6px;
  letter-spacing: -0.01em;
  text-align: left;

  > svg {
    width: 14px;
    height: 14px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 16px;

  @media (max-width: v.$screens-phone) {
    margin-top: 13px;
    gap: 5px;
  }
}

.infoRow {
  display: flex;
  align-items: center;
  gap: 12px;
}

.infoContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}

.ratingsContainerIcon {
  color: var(--color-brand-primaryBlue);
  margin-top: -2px;
  width: 25px;
  height: 25px;

  @media (max-width: v.$screens-phone) {
    width: 18px;
    height: 18px;
  }
}

.guestsContainerIcon {
  color: var(--color-brand-primaryBlue);
  width: 25px;
  height: 25px;

  @media (max-width: v.$screens-phone) {
    width: 18px;
    height: 18px;
  }
}

.passesContainerIcon {
  color: var(--color-brand-primaryBlue);
  --secondaryPassesColor: var(--color-brand-darkBlue);
  width: 25px;
  height: 21px;

  @media (max-width: v.$screens-phone) {
    width: 25px;
    height: 21px;
  }
}

.infoText {
  color: var(--color-text-primary);

  font-size: 19.9px;
  font-weight: 700;
  line-height: 42.89px;
  letter-spacing: -0.01em;
  text-align: left;

  > span {
    color: var(--color-text-secondary);
    font-weight: 500;
  }

  @media (max-width: v.$screens-phone) {
    font-size: 14.46px;
  }
}
