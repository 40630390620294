@use 'screens' as v;

.link {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listingTitle {
  color: var(--color-text-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 21px;

  @media (max-width: v.$screens-phone) {
    font-size: 16px;
    line-height: 21.6px;
  }
}

.imageContainer {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  aspect-ratio: 301 / 210;
  max-width: 100%;

  img {
    object-fit: cover;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 16px;

  @media (max-width: v.$screens-phone) {
    margin-top: 13px;
    gap: 5px;
  }
}

.infoContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.ratingsContainerIcon {
  color: var(--color-brand-primaryBlue);
  margin-top: -2px;
  width: 25px;
  height: 25px;

  @media (max-width: v.$screens-phone) {
    width: 18px;
    height: 18px;
  }
}

.infoText {
  color: var(--color-text-secondary);

  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;

  @media (max-width: v.$screens-phone) {
    font-size: 14.46px;
  }
}
