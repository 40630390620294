@use 'screens' as v;

.description {
  color: var(--color-text-secondary);
  font-size: 18px;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.01em;

  @media (max-width: v.$screens-phone) {
    font-size: 14px;
    line-height: 19px;
  }
}

.readMore.button {
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.01em;

  display: inline-flex;
  text-decoration: underline;

  color: var(--color-text-primary);
  background: var(--color-common-white);

  @media (max-width: v.$screens-phone) {
    font-size: 14px;
    line-height: 19px;
  }
}
