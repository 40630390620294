.darkBlue {
  --seoPrimaryColor: var(--color-brand-darkBlue);
}

.primaryBlue {
  --seoPrimaryColor: var(--color-brand-primaryBlue);
}

.tennisGreen {
  --seoPrimaryColor: var(--color-brand-tennisGreen);
}

.hoopsOrange {
  --seoPrimaryColor: var(--color-brand-hoopsOrange);
}

.homesPink {
  --seoPrimaryColor: var(--color-brand-homesPink);
}
