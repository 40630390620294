@use 'screens' as v;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid var(--color-text-gray2);
  flex: 1 1 21%;

  &:last-of-type {
    border-right: none;
  }

  @media (max-width: v.$screens-smallLaptop) {
    flex: 1 1 30%;
  }
}

.hideOnMobile {
  @media (max-width: v.$screens-smallLaptop) {
    display: none;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 38.99px;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-align: left;

  color: var(--color-text-primary);

  > img {
    width: 25px;
    height: 25px;
  }

  @media (max-width: v.$screens-tablet) {
    font-size: 21px;

    > img {
      width: 13px;
      height: 13px;
    }
  }
}

.description {
  color: var(--color-text-secondary);
  font-size: 26.47px;
  font-weight: 500;
  letter-spacing: -0.01em;
  text-align: center;

  @media (max-width: v.$screens-tablet) {
    font-size: 14px;
  }
}
