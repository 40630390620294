@use 'screens' as v;

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;

  color: var(--color-common-white);

  font-size: 14px;
  font-weight: 600;
  line-height: 100%; /* 14px */
  letter-spacing: -0.14px;

  svg {
    width: 8px;
    height: 8px;
  }

  @media (max-width: v.$screens-tablet) {
    display: none;
  }
}
