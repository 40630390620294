.title {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-text-primary);
  margin-bottom: 10px;
}

.linksContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px var(--headerPadding);
  background: var(--color-common-white);
}

.contentContainerSecondary {
  background: var(--color-background-secondary);
}

.content {
  width: 100%;
  max-width: var(--screens-max);
}
