@use 'screens' as v;

.sectionContainer {
  // Remove the important when next.js fixes css import issue
  @media (max-width: v.$screens-tablet) {
    padding: 0 0 60px !important;
  }
}

.pageTitle {
  color: var(--color-common-white);
  font-size: 52px;
  font-weight: 800;
  line-height: 100%; /* 52px */
  letter-spacing: -0.52px;
  margin: 20px 0;

  width: 40%;

  @media (max-width: v.$screens-smallLaptop) {
    width: 49%;
  }

  @media (max-width: v.$screens-tablet) {
    width: 100%;
    text-align: center;

    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;

    margin: 0 0 15px;
  }
}

.largeTitle {
  font-size: 44px;
  @media (max-width: v.$screens-tablet) {
    font-size: 28px;
  }
}

.description {
  color: var(--color-common-white);

  font-size: 18px;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.18px;

  margin-bottom: 58px;

  width: 40%;

  @media (max-width: v.$screens-smallLaptop) {
    width: 49%;
  }

  @media (max-width: v.$screens-tablet) {
    width: 100%;
    text-align: center;

    font-size: 16px;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.14px;

    margin-bottom: 16px;
  }
}

.container {
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 500px;

  @media (max-width: 815px) {
    height: 525px;
  }

  @media (max-width: v.$screens-tablet) {
    flex-direction: column-reverse;
    align-items: center;
    height: 680px;
  }

  @media (max-width: v.$screens-smallPhone) {
    height: 560px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 55px 45px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--zIndex-base);

  @media (max-width: v.$screens-tablet) {
    top: 62%;
    align-items: center;
    width: 100%;
    padding: 18px;
  }

  @media (max-width: v.$screens-smallPhone) {
    top: 50%;
  }
}

.firstHalf {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 55px 110px 55px 45px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: var(--seoPrimaryColor);
  --mask: radial-gradient(
        132.08px at calc(100% - 182px) 50%,
        #000 99%,
        #0000 101%
      )
      0 calc(50% - 140px) / 100% 280px,
    radial-gradient(132.08px at calc(100% + 112px) 50%, #0000 99%, #000 101%)
      calc(100% - 70px) 50%/100% 280px repeat-y;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  width: 50%;
  height: 100%;
  z-index: var(--zIndex-base);

  @media (max-width: v.$screens-smallLaptop) {
    width: 70%;
  }

  @media (max-width: v.$screens-tablet) {
    --mask: radial-gradient(67.08px at 50% 90px, #000 99%, #0000 101%)
        calc(50% - 60px) 0/120px 100%,
      radial-gradient(67.08px at 50% -60px, #0000 99%, #000 101%) 50% 30px/120px
        100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);

    padding: 30px 20px 60px 20px;

    width: 100%;
    height: 45%;

    margin-top: -40px;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (max-width: v.$screens-smallPhone) {
    height: 58%;
  }
}

.secondHalf {
  position: relative;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;

  aspect-ratio: 2143 / 1500;

  width: 60%;
  height: 100%;
  margin-left: -110px;

  @media (max-width: v.$screens-tablet) {
    width: 100%;
    height: 61%;

    margin-left: 0;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (max-width: v.$screens-smallPhone) {
    height: 50%;
  }
}

.sectionContent {
  max-width: min(v.$screens-max, 95vw);
  @media (max-width: v.$screens-tablet) {
    max-width: 100vw;
  }
}
