@use 'screens' as v;

.container {
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 70%;
  gap: 10px;

  color: var(--color-text-secondary);

  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;

  > svg {
    width: 20px;
    height: 20px;
  }

  > div {
    width: 201px;
    display: flex;
    align-items: center;
    gap: 4px;
    > div {
      margin-bottom: -1px;
    }
  }

  @media (max-width: v.$screens-tablet) {
    padding: 15px;
    border-radius: 12px;
    border: 1px solid var(--color-text-gray2);
    height: auto;
    width: 100%;

    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;

    > div {
      width: 100%;
    }

    > svg {
      width: 15px;
      height: 15px;
    }
  }
}

.input {
  color: var(--color-text-secondary);

  cursor: pointer;

  appearance: none;
  border: 0;
  outline: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
  padding: 0;
  margin: 1px 0 0;
  width: 100%;
  background-color: transparent;

  &:not(:focus) {
    pointer-events: none;
  }

  ::placeholder,
  :disabled {
    opacity: 1;
    --webkit-text-fill-color: var(--color-text-secondary);
    color: var(--color-text-secondary);
  }

  :focus::placeholder {
    color: var(--color-text-tertiary);
  }

  @media (max-width: v.$screens-tablet) {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
  }
}

.inputDisabled {
  touch-callout: none;
  user-select: none;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 3px;
}

.loader {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
