@use 'screens' as v;

.container {
  background: var(--color-text-gray3);

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35%, 1fr));

  row-gap: 60px;
  column-gap: 50px;

  padding: 70px 78px;
  border-radius: 20px;

  @media (max-width: v.$screens-tablet) {
    grid-template-columns: 1fr;
    border-radius: 0;
    padding: 0;
    background: var(--color-common-white);
  }
}

.faq {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.question.button {
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.22px;
  color: var(--color-text-primary);
  background: var(--color-common-transparent);

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  text-align: left;

  > svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
}

.answer {
  font-size: 20px;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: -0.2px;
  color: var(--color-text-secondary);
  white-space: pre-wrap;
}

.link {
  text-decoration: underline;
}
