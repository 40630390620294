@use 'screens' as v;

.container {
  background: var(--color-text-gray3);

  padding: 70px 78px;
  border-radius: 20px;

  @media (max-width: v.$screens-tablet) {
    grid-template-columns: 1fr;
    border-radius: 0;
    padding: 0;
    background: var(--color-common-white);
  }
}
