@use 'screens' as v;

.link {
  color: var(--color-common-white);

  font-size: 14px;

  cursor: pointer;
}

.actionItem {
  margin-bottom: 0px;
  background-color: transparent !important;
}

.sublinkItem {
  color: var(--color-common-white);

  white-space: nowrap;
  display: flex;
  flex-direction: row;

  @media (max-width: v.$screens-phone) {
    text-decoration: underline;
  }
}
