@use 'screens' as v;

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'title image'
    'description image';
  column-gap: 50px;
  row-gap: 24px;
  justify-items: start;

  @media (max-width: v.$screens-mediumLaptop) {
    grid-template-columns: 1fr 350px;
  }

  @media (max-width: v.$screens-tablet) {
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'description';
    row-gap: 10px;
  }
}

.title {
  grid-area: title;
  align-self: end;

  font-size: 36px;
  font-weight: 700;
  line-height: 47.4px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--color-text-primary);

  @media (max-width: v.$screens-tablet) {
    font-size: 26px;
    text-align: center;
  }
}

.description {
  grid-area: description;
  align-self: start;

  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--color-text-secondary);

  @media (max-width: v.$screens-tablet) {
    font-size: 14px;
    line-height: 19.6px;
    text-align: center;
  }
}

.image {
  grid-area: image;
  height: auto;
  width: 100%;
  position: relative;

  @media (max-width: v.$screens-mediumLaptop) {
    width: 350px;
  }

  @media (max-width: v.$screens-tablet) {
    display: none;
    width: 0;
    height: 0;
  }
}

.poolImage {
  aspect-ratio: 628 / 450;
}

.courtsImage {
  aspect-ratio: 596 / 382;
}
