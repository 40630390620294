@use 'screens' as v;

.findSpaceButton.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 70px;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(0, 0, 0, 0.15);

  color: var(--color-text-primary);
  background: var(--color-common-white);

  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: normal;

  padding: 21px 32px;

  @media (max-width: v.$screens-tablet) {
    width: 100%;
    margin-top: 15px;
    padding: 14px 32px;

    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }
}
