.autoCompletePopper {
  margin: 5px 0;
}

.autoCompleteDrawer {
  .MuiDrawer-paper {
    border-radius: 24px 24px 0 0;
    padding: 10px 0;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.drawerCloseButton {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin: 5px 10px;
  padding: 12px;

  &:hover {
    background-color: transparent;
  }
}

.drawerHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px;
}

.drawerTextFieldIcon {
  color: var(--color-common-normalDarkGray);

  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.drawerResultsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
  overflow: auto;
  padding: 0 20px;
  width: calc(100% - 40px);
}

.drawerSection {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  width: calc(100% - 20px);

  > h5 {
    display: flex;
    align-items: center;
  }
}

.drawerItem {
  color: var(--color-text-primary);

  padding: 14px 20px;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.02em;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: var(--color-common-lightestGray);
  }
}

.drawerTextField {
  margin: 10px 0;
  width: 100%;

  & input {
    padding: 12px 0;
    margin-left: 8px;
    font-size: 16px;
  }
}

.drawerSubheaderIcon {
  background-color: var(--color-brand-primaryBlue);

  width: 26px;
  height: 26px;
  margin: 0 15px 0 0;
}

.drawerSubheader {
  color: var(--color-text-primary);

  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0;
  font-weight: 500;
  font-size: 18px;
}
