@use 'screens' as v;

.container {
  background: var(--color-common-white);
  border-radius: 20px;
  border: 1px solid var(--color-text-gray2);
  padding: 60px 24px 40px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.title {
  color: var(--color-text-primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;

  @media (max-width: v.$screens-phone) {
    font-size: 22px;
    line-height: 22px;
  }
}

.description {
  color: var(--color-text-secondary);
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.01em;
  text-align: center;
}

.iconContainer {
  border-radius: 50%;
  background: var(--color-brand-primaryBlue);
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  position: absolute;
  top: -20px;
  margin: 0 auto;

  > svg {
    color: var(--color-common-white);
    width: 24px;
    height: 24px;
  }
}
