@use 'screens' as v;

.link {
  position: relative;
  display: block;
  height: 40px;

  > svg {
    color: var(--color-brand-primaryBlue);

    transition: fill 100ms linear;
  }

  @media (max-width: 315px) {
    display: none;
  }
}

.transparentMobile {
  @media (max-width: v.$screens-tablet) {
    > svg {
      color: var(--color-common-white);
    }
  }
}

.transparentAlways {
  > svg {
    color: var(--color-common-white);
  }
}
