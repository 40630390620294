@use 'screens' as v;

.container {
  --mask: radial-gradient(134.16px at 50% 180px, #000 99%, #0000 101%)
      calc(50% - 120px) 0/240px 51% repeat-x,
    radial-gradient(134.16px at 50% -120px, #0000 99%, #000 101%) 50% 60px/240px
      calc(51% - 60px) repeat-x,
    radial-gradient(134.16px at 50% calc(100% - 180px), #000 99%, #0000 101%)
      calc(50% - 120px) 100%/240px 51% repeat-x,
    radial-gradient(134.16px at 50% calc(100% + 120px), #0000 99%, #000 101%)
      50% calc(100% - 60px) / 240px calc(51% - 60px) repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.container.passes {
  background: var(--color-background-skyBlue3);
  padding: 150px var(--headerPadding);

  @media (max-width: v.$screens-tablet) {
    margin-top: -60px;
    margin-bottom: -60px;
  }
}

.topSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > svg {
    width: 72px;
    height: 60px;
    color: var(--color-brand-primaryBlue);
    --secondaryPassesColor: var(--color-brand-darkBlue);
  }
}

.title {
  font-size: 36px;
  font-weight: 700;
  line-height: 47.4px;
  letter-spacing: -0.01em;
  text-align: center;
  color: var(--color-text-primary);

  margin-top: 18px;

  @media (max-width: v.$screens-tablet) {
    font-size: 26px;
    line-height: 30px;
  }
}

.description {
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  letter-spacing: -0.01em;
  text-align: center;
  color: var(--color-text-secondary);

  margin-top: 24px;

  width: 70%;

  @media (max-width: v.$screens-tablet) {
    font-size: 14px;
    line-height: 19.6px;
    width: 100%;
  }
}

.passesInfoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  margin-top: 85px;

  @media (max-width: v.$screens-tablet) {
    grid-template-columns: 1fr;
    gap: 68px;
  }
}

.passesTypesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  margin-top: 85px;

  @media (max-width: v.$screens-smallLaptop) {
    gap: 25px;
  }

  @media (max-width: v.$screens-tablet) {
    grid-template-columns: 1fr;
    gap: 48px;
    margin-top: 38px;
  }
}

.passesTitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 47.4px;
  letter-spacing: -0.01em;
  text-align: center;
  color: var(--color-text-primary);

  margin-top: 75px;
}
