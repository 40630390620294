@use 'screens' as v;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 38px;

  @media (max-width: v.$screens-phone) {
    padding: 34px;
  }
}

.shadow {
  width: 100%;
  margin-top: 16px;
  > svg {
    width: 100%;
  }
  @media (max-width: v.$screens-phone) {
    display: none;
    margin-top: 0px;
  }
}

.earlyBird.card {
  background: var(--color-brand-primaryBlue);
}

.weekday.card {
  background: var(--color-background-weekdayPass);
}

.anytime.card {
  background: var(--color-background-anyTimePass);
}

.iconContainer {
  width: 150px;
  height: 150px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.earlyBird.iconContainer {
  background: var(--color-background-earlyBirdPass);
}

.weekday.iconContainer {
  background: var(--color-brand-darkBlue);
}

.anytime.iconContainer {
  background: var(--color-brand-darkGray);
}

.title {
  color: var(--color-text-primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;

  margin-top: 30px;

  @media (max-width: v.$screens-phone) {
    margin-top: 34px;
  }
}

.description {
  color: var(--color-text-secondary);
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.01em;
  text-align: center;

  margin-top: 10px;

  width: 70%;

  @media (max-width: v.$screens-phone) {
    font-size: 14px;
    line-height: 18.2px;
  }
}
