@use 'screens' as v;

.container {
  background: var(--color-text-gray3);

  padding: 70px 78px;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  gap: 48px;

  @media (max-width: v.$screens-mediumLaptop) {
    flex-direction: column;
    padding: 50px;
  }

  @media (max-width: v.$screens-phone) {
    padding: 42px 30px;
    border-radius: 20px;
  }
}

.introContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2;
  flex-shrink: 1;

  @media (max-width: v.$screens-phone) {
    align-items: center;
    width: 100%;
  }
}

.title {
  color: var(--color-text-primary);
  font-size: 42px;
  font-weight: 800;
  line-height: 46.2px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 11px;
  margin-top: 0;

  @media (max-width: v.$screens-phone) {
    font-size: 32px;
    line-height: 35.2px;
    text-align: center;
    margin-bottom: 17px;
  }
}

.subtitle {
  color: var(--color-text-secondary);
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-bottom: 65px;

  @media (max-width: v.$screens-phone) {
    font-size: 16px;
    line-height: 19.2px;
    text-align: center;
    margin-bottom: 40px;
  }
}

.findSpaceButton.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 100px;

  color: var(--color-common-white);
  background: var(--seoPrimaryColor);

  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: normal;

  padding: 28px 31px;

  @media (max-width: v.$screens-phone) {
    width: 100%;
    margin-top: 35px;
    padding: 28px 0;

    font-size: 18px;
    letter-spacing: -0.02em;
    text-align: center;
  }
}

.desktopContentContainer {
  flex: 3;

  display: flex;
  flex-direction: column;
  gap: 46px;

  @media (max-width: v.$screens-phone) {
    display: none;
  }
}

.mobileContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 23px;
  position: relative;

  @media (min-width: v.$screens-phone) {
    display: none;
  }
}

.row {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  /* hide scrollbars */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari */
  }

  gap: 55px;

  width: 100vw;
  --widthWithPaddings: calc(30px + var(--headerPaddingViewportWidth));
  margin-left: calc(var(--widthWithPaddings) * -1); // negative margins
  margin-right: calc(var(--widthWithPaddings) * -1); // negative margins
  padding-right: var(--widthWithPaddings);
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-common-border);
}

.selectedDot {
  background: var(--color-text-tertiary);
}
