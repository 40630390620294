@use 'screens' as v;

.sectionContainer {
  // Remove the important when next.js fixes css import issue
  padding-bottom: 0;
  @media (max-width: v.$screens-tablet) {
    padding: 0 0 65px !important;
  }
}

.pageTitle {
  color: var(--color-common-white);
  font-size: 52px;
  font-weight: 800;
  line-height: 100%; /* 52px */
  letter-spacing: -0.52px;
  margin: 20px 0;

  white-space: pre-wrap;

  @media (max-width: v.$screens-tablet) {
    width: 100%;
    text-align: center;

    font-size: 32px;
    letter-spacing: -0.01em;

    margin: 0 0 15px;
  }
}

.largeTitle {
  font-size: 44px;
  @media (max-width: v.$screens-tablet) {
    font-size: 28px;
  }
}

.description {
  color: var(--color-common-white);

  font-size: 22px;
  font-weight: 500;
  line-height: 28.6px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-bottom: 58px;

  width: 40%;

  @media (max-width: v.$screens-smallLaptop) {
    width: 49%;
  }

  @media (max-width: v.$screens-tablet) {
    width: 100%;
    text-align: center;

    font-size: 16px;
    line-height: 20.8px;

    margin-bottom: 42px;
  }
}

.container {
  width: 100%;

  height: 620px;

  @media (max-width: v.$screens-smallPhone) {
    height: 560px;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;

  .imageContainerPicture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  padding: 55px 0 110px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--zIndex-base);

  @media (max-width: v.$screens-tablet) {
    top: unset;
    bottom: -105px;
    align-items: center;
    width: 100%;
    padding: 18px;
  }
}

.sectionContent {
  max-width: min(v.$screens-max, 100vw);
  height: 620px;

  @media (max-width: v.$screens-tablet) {
    max-width: 100vw;
  }

  @media (max-width: v.$screens-smallPhone) {
    height: 560px;
  }
}
