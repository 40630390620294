.container {
  display: grid;
  grid-template-columns: 97px 1fr;
  grid-template-areas:
    'image title'
    'image text';
  align-items: start;
  column-gap: 27px;
  row-gap: 12px;
}

.image {
  grid-area: image;
  width: 97px;
  height: 97px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  > img {
    object-fit: cover;
  }
}

.title {
  grid-area: title;
  color: var(--color-text-primary);
  font-size: 26px;
  font-weight: 800;
  line-height: 32.5px;
  letter-spacing: -0.02em;
  text-align: left;
}

.text {
  grid-area: text;
  color: var(--color-text-secondary);
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
}
