@use 'screens' as v;

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  @media (max-width: v.$screens-tablet) {
    flex-direction: column;
    gap: 35px;
  }
}

.item {
  padding: 28px;
  border-radius: 20px;
  position: relative;
  flex: 1;

  background: var(--seoPrimaryColor);
}

.title {
  color: var(--color-common-white);
  font-size: 24px;
  font-weight: 700;
  line-height: 47.4px; /* 197.5% */
  letter-spacing: -0.24px;

  @media (max-width: v.$screens-tablet) {
    font-size: 16px;
    font-weight: 700;
    line-height: 47.4px; /* 296.25% */
    letter-spacing: -0.16px;
  }
}

.text {
  color: var(--color-common-white);
  font-size: 18px;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.18px;

  @media (max-width: v.$screens-tablet) {
    font-size: 14px;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.14px;
  }
}

.iconContainer {
  position: absolute;
  top: -28px;
  right: 20px;
  border-radius: 50%;
  width: 94px;
  height: 94px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--seoPrimaryColor);

  @media (max-width: v.$screens-tablet) {
    width: 52px;
    height: 52px;
  }
}
