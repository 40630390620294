.column {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--color-text-primary);

  font-size: 24px;
  font-weight: 700;
  line-height: 47.4px; /* 197.5% */
  letter-spacing: -0.24px;
}
