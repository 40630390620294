@use 'screens' as v;

.button.regionButton {
  background-color: var(--color-common-transparent);
  border: 1px solid var(--color-common-white);
  padding: 11px !important;
  border-radius: 13px;
}

.regionButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  min-width: 186px;
  font-size: 14px;
  font-weight: 400;

  color: white;

  @media (max-width: v.$screens-phone) {
    width: 100%;
  }
}

.menuItem {
  font-size: 14px;
  list-style-type: none;
  width: 100%;
  color: var(--color-text-primary);
}

.menu {
  background: var(--color-common-white);

  border-radius: 13px;
  box-shadow: none;
  margin-top: -5px;
}
