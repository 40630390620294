@use 'screens' as v;

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.05));
  border-radius: 70px;
  padding: 10px;

  position: relative;

  background: var(--color-common-white);

  @media (max-width: v.$screens-tablet) {
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-radius: 20px;

    margin-bottom: -50px;
  }

  @media (max-width: v.$screens-phone) {
    margin-bottom: 0;
  }
}

.findSpaceButton.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 70px;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.25);

  color: var(--color-common-white);
  background: var(--color-brand-darkBlue);

  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: normal;

  padding: 21px 32px;

  @media (max-width: v.$screens-tablet) {
    background: var(--seoPrimaryColor);
    border: 2px solid rgba(0, 0, 0, 0.15);

    width: 100%;
    margin-top: 15px;
    padding: 14px 32px;

    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }
}
