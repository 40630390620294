@use 'screens' as v;

.container {
  display: grid;
  grid-template-areas:
    'logo logo logo'
    'links apps social'
    'copy copy region';
  grid-template-columns: 3fr 1fr 1fr;
  row-gap: 50px;
  column-gap: 60px;
  position: relative;
  z-index: var(--zIndex-footerContainer);
  background-color: var(--color-common-nightSwim);
  padding: 60px 120px 100px;

  @media (min-width: v.$screens-tablet) {
    padding: 4vw 50px 6.5vw;
  }

  @media (max-width: v.$screens-tablet) {
    padding: 48px 32px 70px;

    grid-template-areas:
      'logo logo'
      'links links'
      'apps apps'
      'social social'
      'copy region';
    grid-template-columns: 1fr;
  }

  @media (max-width: v.$screens-phone) {
    padding: 48px 32px 110px;
    grid-template-areas:
      'logo'
      'links'
      'apps'
      'social'
      'region'
      'copy';
  }
}

.logo {
  color: var(--color-common-white);
  grid-area: logo;

  width: 137px;
  height: 30px;
}

.contentSection {
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  @media (max-width: v.$screens-tablet) {
    padding: 0;
  }
}

.linkContentSection {
  grid-area: links;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
}

.downloadAppContentSection {
  grid-area: apps;
  flex: 0;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    height: 100%;
    border-left: 1px solid var(--color-common-blueSkies) 4c;
  }

  &:after {
    left: auto;
    right: -30px;
  }

  @media (max-width: v.$screens-tablet) {
    &:before,
    &:after {
      width: 100%;
      height: auto;
      border-left: 0;
      border-top: 1px solid var(--color-common-blueSkies) 4c;
      top: -25px;
      left: 0;
    }

    &:after {
      top: auto;
      bottom: -30px;
    }
  }
}

.contentSectionTitle {
  margin: 0;
  line-height: 1.5;
  color: var(--color-common-white);

  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;

  @media (max-width: v.$screens-tablet) {
    margin-bottom: 15px;
  }
}

.appDownloadBadgesContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: v.$screens-tablet) {
    flex-direction: row;
    gap: 5px;
  }
}

.socialContainer {
  grid-area: social;

  position: relative;
  @media (max-width: v.$screens-tablet) {
    &:after {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 0;
      width: 100%;
      border-top: 1px solid var(--color-common-blueSkies) 4c;
    }
  }
}

.contentSectionBody {
  color: var(--color-common-white);

  display: flex;
  font-size: 14px;
}

.copyright {
  cursor: pointer;
}

.socialLinksContainer {
  display: flex;

  gap: 10px;
  margin-top: 30px;
}

.copyWrapper {
  grid-area: copy;
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: 34px;
  row-gap: 12px;
  flex-wrap: wrap-reverse;

  @media (max-width: v.$screens-phone) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.footerSubLinkItemsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: 24px;

  @media (max-width: v.$screens-phone) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}

.regionWrapper {
  grid-area: region;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.linkSectionContainer {
  width: 100%;
}

.linkItemsContainer {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    margin-bottom: 12px;
  }
}
