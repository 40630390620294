@use 'screens' as v;

.listingsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 65px;
  column-gap: 25px;

  @media (max-width: v.$screens-phone) {
    grid-template-columns: minmax(0, 1fr);
    row-gap: 25px;
  }
}

.linkContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  margin-top: 30px;
}

.linkContainerNoMargin {
  margin-top: 0;
}

.buttonLink {
  border-radius: 12px;
  border: 1px solid var(--color-text-primary);
  color: var(--color-text-primary);
  background: var(--color-common-white);
  padding: 20px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: v.$screens-tablet) {
    display: none;
    width: 0;
    height: 0;
  }
}

.listingItemContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 410px;
}
