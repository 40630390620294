@use 'screens' as v;

.sectionContainer {
  @media (max-width: v.$screens-tablet) {
    padding: 0 0 100px !important;
  }

  @media (max-width: v.$screens-phone) {
    padding: 0 0 50px !important;
  }
}

.pageTitle {
  color: var(--color-brand-darkBlue);
  font-size: 52px;
  font-weight: 800;
  line-height: 54.6px;
  letter-spacing: -0.01em;
  text-align: center;

  margin-bottom: 35px;

  @media (max-width: v.$screens-laptop) {
    font-size: 40px;
    line-height: 41.6px;
  }

  @media (max-width: v.$screens-smallLaptop) {
    font-size: 32px;
    line-height: 33.6px;
    margin-bottom: 15px;
  }

  @media (max-width: v.$screens-tablet) {
    font-size: 32px;
    line-height: 33.6px;
    margin-bottom: 14px;
  }

  z-index: var(--zIndex-base);
}

.description {
  color: var(--color-text-secondary);

  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  letter-spacing: -0.01em;
  text-align: center;

  max-width: 750px;

  flex: 1;

  @media (max-width: v.$screens-laptop) {
    font-size: 20px;
    line-height: 22.8px;

    max-width: 650px;
  }

  @media (max-width: v.$screens-mediumLaptop) {
    max-width: 500px;
  }

  @media (max-width: v.$screens-smallLaptop) {
    font-size: 16px;
    line-height: 18.8px;
  }

  @media (max-width: v.$screens-tablet) {
    font-size: 14px;
    line-height: 16.8px;
  }

  z-index: var(--zIndex-base);
}

.container {
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  aspect-ratio: 1281 / 565;
  padding: 84px 0 37px;

  @media (max-width: v.$screens-mediumLaptop) {
    padding: 50px 0 20px;
  }

  @media (max-width: v.$screens-smallLaptop) {
    padding: 20px 0 15px;
  }

  @media (max-width: v.$screens-tablet) {
    aspect-ratio: 380 / 400;
    padding: 100px 25px 0;
  }

  @media (max-width: v.$screens-phone) {
    aspect-ratio: 380 / 650;
  }
}

.sectionContent {
  max-width: min(v.$screens-max, 95vw);
  @media (max-width: v.$screens-tablet) {
    max-width: 100vw;
  }
}

.picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: v.$screens-tablet) {
    object-fit: cover;
  }
}
