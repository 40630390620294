@use 'screens' as v;

.linksContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 1fr));
  row-gap: 16px;
  column-gap: 12px;

  @media (max-width: v.$screens-tablet) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 12px;
  }
}
