@use 'screens' as v;

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 27px;

  position: relative;

  @media (max-width: v.$screens-tablet) {
    gap: 0px;
    border-radius: 70px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: var(--color-common-white);
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.05));
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }
}

.findSpaceButton.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 70px;

  flex-grow: 1;

  color: var(--color-common-white);
  background: var(--seoPrimaryColor);

  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: normal;

  padding: 21px 32px;

  @media (max-width: v.$screens-tablet) {
    width: 100%;
    margin-top: 15px;
    padding: 14px 32px;

    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }
}
