@use 'screens' as v;

.container {
  display: flex;

  width: 100%;
  position: sticky;
  z-index: var(--zIndex-headerOpaque);
  top: 0;
  left: 0;

  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;

  padding: 10px 0;
  background: var(--color-common-white);
  border-bottom: 1px solid var(--color-text-gray2);

  $transition-speed: 100ms;

  transition: padding $transition-speed linear,
    background-color $transition-speed linear, border $transition-speed linear;
}

.transparentMobile {
  @media (max-width: v.$screens-tablet) {
    z-index: var(--zIndex-headerNonOpaque);
    background: var(--color-common-transparent);
    border-bottom: none;
  }
}

.transparentAlways {
  z-index: var(--zIndex-headerNonOpaque);
  background: var(--color-common-transparent);
  border-bottom: none;
}

.content {
  width: 100%;
  max-width: v.$screens-max;
  position: relative;
}

.nonThresholdPadding {
  padding: 0 var(--headerPadding);
}

.thresholdPadding {
  @media (max-width: v.$screens-max) {
    padding: 0 var(--headerPadding);
  }
}

.mainPadding {
  padding: 10px var(--headerPadding);
}
