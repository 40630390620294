.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 0 0 auto;
  width: calc(100% - var(--widthWithPaddings));

  background: var(--color-common-white);
  padding: 31px 18px;
  border-radius: 17px;

  &:first-of-type {
    margin-left: var(--widthWithPaddings);
  }
}

.image {
  width: 97px;
  height: 97px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-bottom: 23px;

  > img {
    object-fit: cover;
  }
}

.title {
  color: var(--color-text-primary);
  font-size: 22px;
  font-weight: 800;
  line-height: 23.1px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 13px;
}

.text {
  color: var(--color-text-secondary);
  font-size: 16px;
  font-weight: normal;
  line-height: 19.2px;
  letter-spacing: -0.02em;
  text-align: center;
}
