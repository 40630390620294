@use 'screens' as v;

.container {
  --seoPrimaryColor: var(--color-brand-primaryBlue);

  @media (max-width: v.$screens-tablet) {
    margin-top: -60px;
  }
}

.sectionTitle {
  color: var(--color-text-primary);
  font-size: 32px;
  font-weight: 700;
  line-height: 47.4px;
  letter-spacing: -0.32px;

  margin-top: 0;

  width: 100%;
}

.sectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px var(--headerPadding);
  background: var(--color-common-white);

  box-sizing: border-box;
}

.sectionContainerNoTopPadding {
  padding-top: 0;
}

.sectionContainerNoPadding {
  padding: 60px 0;
}

.sectionContainerSecondary {
  background: var(--color-background-secondary);
}

.sectionContainerLightBlue {
  background: var(--color-background-skyBlue3);
}

.sectionContent {
  width: 100%;
  max-width: v.$screens-max;
  position: relative;
}

.sectionContentBordered {
  border-top: 1px solid var(--color-text-gray2);
  padding-top: 40px;
  margin-top: 40px;

  @media (max-width: v.$screens-phone) {
    border-top: none;
    padding-top: 0px;
  }

  &:first-of-type {
    padding-top: 0px;
    margin-top: 0px;
    border-top: none;
  }
}

.sectionContentMarkdown {
  font-size: 20px;
  font-weight: 400;
  line-height: 33.4px;
  letter-spacing: -0.01em;
  text-align: left;

  color: var(--color-text-secondary);

  @media (max-width: v.$screens-phone) {
    font-size: 14px;
    line-height: 21px;
  }
}

.sectionContentMarkdown a {
  color: var(--color-brand-primaryBlue);
}

.link {
  color: var(--color-text-primary);
  font-size: 22px;
  font-weight: 600;
  line-height: 47.4px; /* 215.455% */
  letter-spacing: -0.22px;
  text-decoration-line: underline;

  @media (max-width: v.$screens-phone) {
    font-size: 16px;
    line-height: 34px;

    letter-spacing: -0.01em;
  }
}

.link:hover {
  color: var(--color-brand-primaryBlue);
}

.linkSecondary {
  color: var(--color-text-secondary);
}

.linksContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: v.$screens-tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: v.$screens-phone) {
    grid-template-columns: 1fr;
  }
}

.linksContainerGap {
  @media (max-width: v.$screens-phone) {
    row-gap: 38px;
  }
}

.searchBarButton.button {
  display: flex;
  align-items: center;
  padding: 0 30px;
  outline: none;

  color: var(--color-text-secondary);

  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;

  gap: 10px;

  @media (max-width: v.$screens-tablet) {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;

    border: 1px solid var(--color-text-tertiary);

    padding: 15px;
    width: 100%;
  }
}

.searchBarButton.button.top {
  @media (max-width: v.$screens-tablet) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: 0;
  }
}

.searchBarButtonBottom {
  @media (max-width: v.$screens-tablet) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top: 0;
  }
}

.desktopOnly {
  @media (max-width: v.$screens-tablet) {
    display: none;
    width: 0;
    height: 0;
  }
}
