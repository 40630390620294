.socialLink {
  color: var(--color-common-white);

  width: 35px;
  height: 35px;
  padding: 5px;
  margin-right: 10px;
  box-sizing: border-box;

  > svg {
    width: 100%;
    height: 100%;
  }
}
