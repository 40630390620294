@use 'screens' as v;

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  align-items: start;

  @media (max-width: v.$screens-smallLaptop) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: v.$screens-tablet) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

.reviewContainer {
  display: grid;
  grid-template-areas:
    'image name'
    'image listing'
    'image text';
  grid-template-columns: auto 1fr;
  row-gap: 0px;
  column-gap: 18px;

  @media (max-width: v.$screens-tablet) {
    column-gap: 11px;
  }
}

.imageContainer {
  grid-area: image;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1;
  width: 66px;
  height: 66px;

  @media (max-width: v.$screens-tablet) {
    width: 48px;
    height: 48px;
  }
}

.name {
  grid-area: name;
  color: var(--color-text-primary);

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;

  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.2px;

  @media (max-width: v.$screens-tablet) {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.01em;
  }
}

.listing {
  grid-area: listing;
  color: var(--color-text-secondary);

  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  letter-spacing: -0.01em;
  text-align: left;
  margin-bottom: 10px;

  @media (max-width: v.$screens-tablet) {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
  }
}

.text {
  grid-area: text;
  color: var(--color-text-secondary);
  font-size: 16px;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.16px;

  @media (max-width: v.$screens-tablet) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
  }
}

.stars {
  flex-shrink: 0;
  grid-area: stars;
  color: var(--color-brand-primaryBlue);
  line-height: 20px;
  @media (max-width: v.$screens-tablet) {
    line-height: 16px;
  }
}
